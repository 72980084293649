<template>
  <div class="navigate-bar-wrap text-no-select" :class="headerMode">
    <div class="bg"></div>
    <div class="navigate-bar content-block">
      <div class="logo" @click="backHome">
        <img src="@/assets/image/logo.png" alt="" />
      </div>

      <div id="menu" class="menu en">
        <span class="menu-con">
          <a :href="HREF_HUGGING_FACE" target="_blank" name="Hugging Face">
            <svg-icon name="company-hugging-face" />
          </a>
        </span>
        <span class="menu-con">
          <a :href="HREF_GITHUB" target="_blank" name="Github">
            <svg-icon name="company-github" />
          </a>
        </span>
        <!-- <span class="menu-con">
          <a :href="HREF_DISCORD" target="_blank" name="Discord">
            <svg-icon name="company-discord" />
          </a>
        </span> -->
        <span class="menu-con">
          <a :href="HREF_TWITTER" target="_blank" name="X">
            <svg-icon name="company-x" />
          </a>
        </span>
        <!-- <span>/</span>
      <span class="menu-con">
        <a href="mailto://zszn@molardata.com" target="_blank">Mail</a>
      </span> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import svgIcon from '@/components/SvgIcon.vue'
import {
  HREF_HUGGING_FACE,
  HREF_GITHUB,
  HREF_DISCORD,
  HREF_TWITTER
} from '../../../../public/href.js'

const isMobileFn = () => {
  let info = navigator.userAgent
  let agents = ['Android', 'iPhone', 'Windows Phone', 'iPad', 'iPod']
  let isPc = true
  for (let i = 0; i < agents.length; i++) {
    if (info.indexOf(agents[i]) > 0) {
      isPc = false
      break
    }
  }
  return !isPc
}

const isMobile = ref<boolean>(false)
const headerMode = ref<'opacity' | 'normal'>('opacity')

onMounted(() => {
  isMobile.value = isMobileFn()

  const scrollEl = isMobile.value
    ? document.querySelector('.app-content')
    : document.querySelector('body')
  scrollEl?.addEventListener('scroll', function () {
    const scrollPosition = scrollEl.scrollTop
    if (scrollPosition > 120) {
      headerMode.value = 'normal'
    } else {
      headerMode.value = 'opacity'
    }
  })
})

const backHome = () => {
  if(window.location.pathname === '/') return
  window.location.replace('/');
}
</script>

<style lang="less" scoped>
@import '@/styles/variable.less';

.navigate-bar-wrap {
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 2023;
  left: 0;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    // background: #000;
    // opacity: 0;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
  }
  &.normal {
    .bg {
      opacity: 1;
    }
  }
}
.navigate-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;

  .logo {
    justify-self: flex-start;
    cursor: pointer;
    img {
      width: 143px;
      height: 50px;
    }
  }

  .en {
    font-weight: 400;
    letter-spacing: 0;
  }
  .menu {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    position: relative;
    gap: 16px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
  }
  .menu-con {
    display: inline-block;
    border-bottom: solid 1px rgba(255, 255, 255, 0);
    transition: 0.2s;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
  }
  .menu-con:hover {
    border-bottom: solid 1px rgba(255, 255, 255, 1);
  }

  a {
    color: #fff;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 767px) {
  .navigate-bar-wrap {
    height: calc(130px * @pxToMobile);
    .navigate-bar {
      .logo {
        img {
          width: calc(143px * @pxToMobile);
          height: calc(50px * @pxToMobile);
        }
      }
      .menu {
        display: none;
      }
    }
  }
}
</style>
