<template>
  <div
    class="m-icon"
    :class="{ center }"
    :style="{
      width: `${size || width}px`,
      height: `${size || height}px`,
      maskImage: props.name ? `url(${iconUrl})` : 'unset'
    }"
  ></div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    name: string
    width?: number | string
    height?: number | string
    size?: number | string
    suffix?: string
    center?: boolean
  }>(),
  { width: undefined, height: undefined, size: 20, suffix: 'svg', center: false }
)

const iconUrl = computed(() => {
  if (props.name.includes('/')) {
    const keys = props.name.split('/')
    const key = keys[keys.length - 1]
    if (props.name.includes('tool/'))
      return new URL(`../assets/icons/tool/${key}.${props.suffix}`, import.meta.url).href
  }
  return new URL(`../assets/icons/${props.name}.${props.suffix}`, import.meta.url).href
})
</script>
<style lang="less" scoped>
.m-icon {
  user-select: none;
  background-color: currentColor;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  &.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
