import { App } from 'vue'
import { createI18n } from 'vue-i18n'

export default async (app: App) => {
  const locale = import.meta.env.VITE_APP_LANG
  const modules = import.meta.glob('./languages/**/*.json')
  const message = {}
  for (const k of Object.keys(modules)) {
    const moduleName = k.replace('./languages/', '').split('/')[0]
    if (!k.includes(locale)) continue
    message[moduleName] = ((await modules[k]()) as any).default
  }

  const i18n = createI18n({
    legacy: false,
    locale,
    messages: {
      [locale]: message
    }
  })

  app.use(i18n)
}
