<template>
  <div class="app-header">
    <!-- <header-news /> -->
    <navigate-bar />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import HeaderNews from './HeaderNews.vue'
import NavigateBar from './NavigateBar/index.vue'

onMounted(() => {})
</script>

<style lang="less" scoped>
.app-header {
  z-index: 100;
  position: relative;
}
</style>
