<template>
  <m-icon
    v-show="isShow"
    name="bounding-box"
    size="32"
    :style="{ transform: `translate3d(${point[0]}px,${point[1]}px,0)` }"
    class="mouse-cursor"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import MIcon from '../MIcon.vue'
const isShow = ref<boolean>(false)
const point = ref<[number, number]>([0, 0])

onMounted(() => {
  document.addEventListener('pointermove', (e: MouseEvent) => {
    point.value = [e.clientX, e.clientY]
  })
  document.addEventListener('pointerenter', () => {
    isShow.value = true
  })
  document.addEventListener('pointerleave', () => {
    isShow.value = false
  })
})
</script>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .mouse-cursor {
    display: none;
  }
}
</style>
