<template>
  <div class="app-layout" :class="{ 'is-mobile': isMobile }">
    <!-- <scroll-smoother v-if="!isMobile"> -->
    <template v-if="!isMobile">
      <app-mouse-cursor />
      <app-header />
      <div class="app-content">
        <div class="router-view">
          <router-view />
        </div>
        <router-footer />
      </div>
    </template>
    <!-- </scroll-smoother> -->
    <template v-else>
      <app-header />
      <div class="app-content">
        <div class="router-view">
          <router-view />
        </div>
        <router-footer />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import AppHeader from '@/components/AppHeader/index.vue'
import RouterFooter from '@/components/RouterFooter/index.vue'
import AppMouseCursor from '@/components/AppMouseCursor/AppMouseCursor.vue'
import ScrollSmoother from '@/components/ScrollSmoother/index.vue'
import { onMounted, ref } from 'vue'

const isMobileFn = () => {
  let info = navigator.userAgent
  let agents = ['Android', 'iPhone', 'Windows Phone', 'iPad', 'iPod']
  let isPc = true
  for (let i = 0; i < agents.length; i++) {
    if (info.indexOf(agents[i]) > 0) {
      isPc = false
      break
    }
  }
  return !isPc
}

const isMobile = ref<boolean>(false)

onMounted(() => {
  isMobile.value = isMobileFn()
})
</script>

<style lang="less" scoped>
.app-layout {
  cursor: default;
  position: relative;
  height: 100%;
  background-color: #000;
  .router-view {
    min-height: 100vh;
  }
  .m-icon {
    pointer-events: none;
    z-index: 2032;
    position: fixed;
    top: 8px;
    left: 8px;
    color: #5e5ce5;
    transition: rotate 1.3s;
  }
  &.is-mobile {
    .router-view {
      min-height: 100vh;
    }
    .app-content {
      overflow-y: auto;
      height: 100vh;
      position: relative;
    }
  }
}
</style>
