<template>
  <div class="router-footer content-block">
    <svg-icon name="logo" size="120px" />
    <div class="body-wrap">
      <div class="text-wrap">
        <p class="title text-no-select">Join us in shaping the future of technology</p>
        <p class="date text-no-select">2077AI@2023-{{ thisYear }}</p>
      </div>
      <div class="other-contact-info text-no-select">
        <p>
          <a :href="HREF_HUGGING_FACE" target="_blank">
            <svg-icon name="company-hugging-face" size="26px" />
            Hugging Face
          </a>
        </p>
        <p>
          <a :href="HREF_GITHUB"  target="_blank">
            <svg-icon name="company-github" size="26px" />
            Github
          </a>
        </p>
        <!-- <p>
          <a :href="HREF_DISCORD"  target="_blank">
            <svg-icon name="company-discord" size="26px" />
            Discord
          </a>
        </p> -->
        <p>
          <a :href="HREF_TWITTER"  target="_blank">
            <svg-icon name="company-x" size="26px" />
            X
          </a>
        </p>
        <p class="date text-no-select">2077AI@2023-{{ thisYear }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="tsx" setup>
import svgIcon from '@/components/SvgIcon.vue'
import {
  HREF_HUGGING_FACE,
  HREF_GITHUB,
  HREF_DISCORD,
  HREF_TWITTER
} from '../../../public/href.js'

const thisYear = new Date().getFullYear()
</script>

<style lang="less" scoped>
@import '@/styles/variable.less';

.router-footer {
  z-index: 99;
  position: relative;
  padding: 50px 40px 190px;
  display: flex;
  .body-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    .other-contact-info{
      margin-left: auto;
      padding-top: 14px;
      height: 100%;
    }
  }
  .text-wrap {
    padding-left: 15px;
    .title {
      width: 820px;
      color: #fff;
      font-family: Viga;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .date {
      margin-top: 24px;
      color: #fff;
      text-align: justify;
      font-family: 'PingFang SC';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 1px;
    }
  }

  .other-contact-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    margin-left: auto;
    p {
      a,
      a:link,
      a:visited,
      a:hover,
      a:active {
        display: flex;
        align-items: center;
        gap: 16.81px;
        color: #fff;
        text-align: justify;
        font-family: 'PingFang SC';
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0.5px;
        text-decoration: none;
      }
    }
    .date {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .router-footer {
    align-items: start;
    padding: calc(50px * @pxToMobile) calc(40px * @pxToMobile) calc(124px * @pxToMobile);
    :deep(> .svg-icon) {
      width: calc(260px * @pxToMobile);
      height: auto;
      // height: calc(120px * @pxToMobile);
    }
    .body-wrap {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(26px * @pxToMobile);
      padding-left: calc(15px * @pxToMobile);
    }
    .text-wrap {
      padding-left: 0;
      .title {
        width: auto;
        font-size: calc(48px * @pxToMobile);
        line-height: calc(72px * @pxToMobile);
        letter-spacing: calc(1px * @pxToMobile);
      }
      .date {
        display: none;
      }
    }

    .other-contact-info {
      gap: calc(26px * @pxToMobile);
      margin-left: 0;
      p {
        a,
        a:link,
        a:visited,
        a:hover,
        a:active {
          gap: calc(16px * @pxToMobile);
          font-size: calc(22px * @pxToMobile);
          line-height: calc(36px * @pxToMobile);
          letter-spacing: calc(0.5px * @pxToMobile);
          .svg-icon{
            width: calc(26px * @pxToMobile);
            height: calc(26px * @pxToMobile);
          }
        }
      }
      .date {
        display: block;
        font-size: calc(22px * @pxToMobile);
        line-height: calc(36px * @pxToMobile);
        letter-spacing: calc(1px * @pxToMobile);
      }
    }
  }
}
</style>
