import { App } from 'vue'
import baseRoutes from './routes/baseRoutes'
import { createRouter, createWebHistory } from 'vue-router'

export const router = createRouter({
  history: createWebHistory(),
  routes: [...baseRoutes],
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 })
})

// 路由切换时清除 URL 中的 hash
router.beforeEach((to, from, next) => {
  if (window.location.hash) {
    history.replaceState(null, '', window.location.pathname + window.location.search);
  }
  next();
});

export function setupRouter(app: App): void {
  app.use(router)
}
